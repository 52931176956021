
import React from 'react'
import Slider from 'react-slick'

import Layout from '../../../components/layout'
import Nav from '../../../components/nav'
import SEO from '../../../components/seo'
import '../../../styles/index.scss'

// Content components
import OptBtn from '../../../components/button'

import bannerImg from '../../../images/products/whatsapp/banner.png'
import mobile from '../../../images/home/redesign01/mobile.png'

// features
import feature01 from '../../../images/home/features/icon01.svg'
import feature02 from '../../../images/home/features/icon02.svg'
import feature03 from '../../../images/home/features/icon03.svg'
import feature04 from '../../../images/home/features/icon04.svg'

// customers
import customer_aldeas from '../../../images/home/clients/customer_aldeas.png'
import customer_baristi from '../../../images/home/clients/customer_baristi.png'
import customer_ccti from '../../../images/home/clients/customer_ccti.png'
import customer_conoc from '../../../images/home/clients/customer_conoc.png'
import customer_crucijuegos from '../../../images/home/clients/customer_crucijuegos.png'
import customer_descuentos from '../../../images/home/clients/customer_descuentos.png'
import customer_ginza from '../../../images/home/clients/customer_ginza.png'
import customer_ioimporto from '../../../images/home/clients/customer_ioimporto.png'
import customer_mcc from '../../../images/home/clients/customer_mcc.png'
import customer_med from '../../../images/home/clients/customer_med.png'
import customer_reba from '../../../images/home/clients/customer_reba.png'
import customer_san_lorenzo from '../../../images/home/clients/customer_san_lorenzo.png'
import customer_whyline from '../../../images/home/clients/customer_whyline.png'
import customer_xe_seguridad from '../../../images/home/clients/customer_xe_seguridad.png'

const IndexPage = () => {
    /*
    ***************
    * SLIDER SETTINGS *
    ***************
    */
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ]
    }

    return (
        <Layout lang="es">

            <SEO
                title="Optiwe - Comunicacion Multiagente en WhatsApp Business API."
                description="Automatización y atención multiagente en WhatsApp para optimizar tu ventas y la atención al cliente."
                lang="es"
                locales={
                    [
                        { hreflang: 'es', href: 'https://optiwe.com/es/canales/whatsapp/' }
                    ]
                }
            />

            <main className="container-fluid ow-banner">
                {
                    /*
                    ^*********
                    * HEADER *
                    **********
                    */
                }
                <Nav lang="es" showButtons />

                {
                    /*
                    ***************
                    * MAIN BANNER *
                    ***************
                    * TODO: If the banner is used in another view, create a <Banner /> component
                    */
                }
                <div className="container ow-banner__content">
                    <div className="row">
                        <div className="col-xl-6 col-xxl-6 ow-banner__home-content">
                            <h2 className="ow-banner__title">
                                Automatiza tus ventas y atiende con múltiples agentes en <strong>WhatsApp</strong></h2>
                            <p className="ow-banner__text">
                                Optiwe te ayuda a escalar tus equipos de ventas y servicio al cliente en <strong>WhatsApp</strong>. Con nuestra plataforma basada en Inteligencia Artificial, puedes automatizar conversaciones y derivar clientes a múltiples agentes utilizando un <strong>único número de WhatsApp centralizado</strong>.
                            </p>
                            <div className="row">
                                <div className="col-xl-4 mb-3 mb-md-0">
                                    <OptBtn isLink rounded small href="/es/crear-cuenta/">
                                        Agendar demo
                                    </OptBtn>
                                </div>
                                <div className="col-xl-4">
                                    <OptBtn isLink rounded small href="/es/crear-cuenta/">
                                        Probar gratis
                                    </OptBtn>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-xxl-7">
                            <img src={bannerImg} alt="optiwe" className="ow-banner__img" />
                        </div>
                    </div>
                </div>
            </main>

            {
                /*
                ************
                * BENEFITS *
                ************
                */
            }
            <section className="container-fluid ow-section--cards" id="benefits">
                <div className="container">

                    <h2 className="ow-section--cards__title"><span className="bold">Beneficios de atender a tus clientes en WhatsApp</span></h2>
                    <p className="ow-section--cards__subtitle">Mejora tu atención al cliente y ventas digital con Optiwe! </p>
                    <div className="row mt-md-5 ow-section--row ow-section--benefits">
                        <div className="col-md-4 ow-section--col mt-3 mt-md-0">
                            <div className="ow-benefit">
                                <p className="ow-benefit__number_blue">24/7</p>
                                <p className="ow-benefit__text">responde a tus clientes por WhatsApp a todo momento</p>
                            </div>
                        </div>
                        <div className="col-md-4 ow-section--col mt-3 mt-md-0">
                            <div className="ow-benefit">
                                <p className="ow-benefit__number_blue">15%</p>
                                <p className="ow-benefit__text">de aumento en tus ventas digitales</p>
                            </div>
                        </div>
                        <div className="col-md-4 ow-section--col mt-3 mt-md-0">
                            <div className="ow-benefit">
                                <p className="ow-benefit__number_blue">30%</p>
                                <p className="ow-benefit__text">de automatización en las conversaciones</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {
                /*
                ************
                * FEATURES *
                ************
                */
            }
            <section className="container-fluid ow-section ow-section--features" id="features">
                <div className="container features">
                    <div className="row">
                        <div className="col-md-12 features__content">
                            <h2 className="ow-section__title"><span className="bold">Funciones integradas a WhatsApp</span></h2>
                            <p className="ow-section__text">Queres atender mejor a tus clientes y lograr más ventas en WhatsApp? Deja de usar soluciones costosas de la década pasada que no te dan resultados. En Optiwe, cada función está basada en AI y está diseñada para ayudarte a optimizar tus interacciones digitales con clientes.</p>
                            <p className="ow-section__text"><span className="highlight">Comienza tu viaje de transformación de AI con nosotros <span role="img" aria-label="smile-icon">😀</span> </span></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mt-3">
                            <div className="ow-card">
                                <img src={feature01} alt="Optiwe feature01" />
                                <h3 className="ow-card__title">Asistente Inteligente y Chatbot para WhatsApp</h3>
                                <p className="ow-card__text">Con Optiwe, podes entrenar un Asistente Inteligente o Chatbot para automatizar respuestas en WhatsApp.</p>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="ow-card">
                                <img src={feature02} alt="Optiwe" />
                                <h3 className="ow-card__title">Live Chat centralizado para WhatsApp</h3>
                                <p className="ow-card__text">Gracias a nuestra integración con WhatsApp Business API es posible que múltiples agentes atiendan simultáneamente un mismo número de WhatsApp.</p>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="ow-card">
                                <img src={feature03} alt="Optiwe" />
                                <h3 className="ow-card__title">WhatsApp Business API</h3>
                                <p className="ow-card__text">Estamos integrados de manera oficial con WhatsApp Business API. Optiwe te ayuda en todo el proceso para obtener tu número de WhatsApp Business API.</p>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="ow-card">
                                <img src={feature04} alt="Optiwe" />
                                <h3 className="ow-card__title">Reporte y análisis de datos</h3>
                                <p className="ow-card__text">Optiwe provee métricas en tiempo real con la información sobre las preguntas que tus clientes más hacen, lista de clientes contentos y enojados y muchos datos mas que te ayudarán a entender mejor a tu cliente en WhatsApp.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {
                /*
                ************
                * CHANNELS *
                ************
                */
            }
            <section className="container-fluid ow-section ow-section--channels" id="channels">
                <div className="container">
                    <div className="row d-flex align-items">
                        <div className="col-md-6 d-flex align-items-center order-1 order-ms-2">
                            <div className="ow-section__content">
                                <h2 className="ow-section__title"><span className="bold">Utiliza WhatsApp cómo canal de comunicación</span></h2>
                                <h3 className="ow-section__subtitle">Tu negocio con presencia WhatsApp.</h3>
                                <p className="ow-section__text">Utiliza nuestro Asistente Inteligente en tu sitio web, WhatsApp y muchos canales digitales modernos más.</p>
                                <OptBtn isLink rounded small href="/es/crear-cuenta/">
                                    Probar gratis
                                </OptBtn>

                            </div>
                        </div>
                        <div className="col-md-6 order-2 order-md-1">
                            <img src={mobile} alt="Optiwe" className="img-responsive" />
                        </div>
                    </div>
                </div>
            </section>

            {
                /*
                ************
                * CUSTOMERS *
                ************
                */
            }
            <section className="clients-shapes">
                <div className="client_top_shape_wrapper">
                    <svg className="svg_top_shape" id="client_top_shape" data-name="client_top_shape" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" viewBox="0 0 1920 595"><path className="clients_top_shape" d="M1920,595V0c-89.19,9.65-163.93,79.77-218.34,153.38-59,79.8-107.78,170.9-189.35,227.3-42.59,29.6-92,47.9-142.07,60.9-54.28,14.1-110.67,22.1-166.55,17.3-88.88-7.6-172.16-47.2-260.44-59.5a433,433,0,0,0-255.13,43c-37.89,19.4-72.78,44.3-110.57,63.9C472.28,561,345,571.38,232.24,534.68,151.25,508.33,76.61,458.85,0,420.92V595Z" /></svg>
                </div>
                <div className="container-fluid ow-section ow-section--success-stories" id="success-stories">
                    <div className="container success-stories">
                        <div className="row justify-content-center mb-5">
                            <div className="col-md-10">
                                <h2 className="ow-section__title"><span className="bold">Sumate a las empresas que ya digitalizaron sus ventas y soporte al cliente con Optiwe</span></h2>
                            </div>
                            <div className="row">
                                <div className="col-md-10">
                                </div>
                            </div>
                        </div>
                        <div className="mb-5">
                            <Slider {...sliderSettings}>
                                <div className="ow-client-wrapper">
                                    <img src={customer_aldeas} alt="optiwe" className="ow-features__img" />
                                </div>
                                <div className="ow-client-wrapper">
                                    <img src={customer_baristi} alt="optiwe" className="ow-features__img" />
                                </div>
                                <div className="ow-client-wrapper">
                                    <img src={customer_reba} alt="optiwe" className="ow-features__img" />
                                </div>
                                <div className="ow-client-wrapper">
                                    <img src={customer_ccti} alt="optiwe" className="ow-features__img" />
                                </div>
                                <div className="ow-client-wrapper">
                                    <img src={customer_conoc} alt="optiwe" className="ow-features__img" />
                                </div>
                                <div className="ow-client-wrapper">
                                    <img src={customer_crucijuegos} alt="optiwe" className="ow-features__img" />
                                </div>
                                <div className="ow-client-wrapper">
                                    <img src={customer_descuentos} alt="optiwe" className="ow-features__img" />
                                </div>
                                <div className="ow-client-wrapper">
                                    <img src={customer_ginza} alt="optiwe" className="ow-features__img" />
                                </div>
                                <div className="ow-client-wrapper">
                                    <img src={customer_ioimporto} alt="optiwe" className="ow-features__img" />
                                </div>
                                <div className="ow-client-wrapper">
                                    <img src={customer_mcc} alt="optiwe" className="ow-features__img" />
                                </div>
                                <div className="ow-client-wrapper">
                                    <img src={customer_med} alt="optiwe" className="ow-features__img" />
                                </div>
                                <div className="ow-client-wrapper">
                                    <img src={customer_san_lorenzo} alt="optiwe" className="ow-features__img" />
                                </div>
                                <div className="ow-client-wrapper">
                                    <img src={customer_whyline} alt="optiwe" className="ow-features__img" />
                                </div>
                                <div className="ow-client-wrapper">
                                    <img src={customer_xe_seguridad} alt="optiwe" className="ow-features__img" />
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
                <svg id="client_bottom_shape" data-name="client_bottom_shape" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 170"><path className="client_bottom_shape" d="M0,0V126.36c143.66,40.06,284.7,51.63,445.48,4.58,236.34-69.2,456.88-50.6,696.62-15.6,149.46,21.9,301.72,27,451.88,8.7,111.21-13.59,217-45.86,326-64.76V0Z" /></svg>
            </section>

        </Layout>
    )
}

export default IndexPage
